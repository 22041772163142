import React from 'react'
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledContainer = styled.section` 
  background: ${props => props.background || 'transparent'};
`;

const StyledContainerInner = styled.div`
  max-width: 960px;
  justify-content: space-around;
  display: ${props => props.display || 'block'};
  align-items: center;
  padding: ${props => props.padding || '1.5rem 0'};
  margin: ${props => props.margin || '0 auto'};
  flex-direction: column;
  position: relative;

  @media only screen and (max-width: 600px) {
    padding: 0 1rem;
  }
  
  @media (min-width: 768px) {
    padding: ${props => props.padding || '1rem'};
  }
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Container = ({ background, margin, padding, display, children }) => {
  return (
    <StyledContainer background={background}>
      <StyledContainerInner display={display} margin={margin} padding={padding}>
        {children}
      </StyledContainerInner>
    </StyledContainer>
  )
}

Container.propTypes = {
    background: PropTypes.string,
    margin: PropTypes.string,
    display: PropTypes.string,
    children: PropTypes.node.isRequired,
}

export default Container