import React from "react"
import PropTypes from "prop-types"

import BackgroundImage from '../atoms/BackgroundImage'
import Container from "../molecules/container"
import Footer from "../organisms/footer"

import "../layout.css"

const Landingpage = ({title, primaryColor, showLogo, children}) => {
    return (
        <BackgroundImage color="Gold">
            <Container>
                <main style={{textAlign: 'center'}}>{children}</main>
            </Container>
            <Footer page='landingpage' />
        </BackgroundImage>
    )
}

Landingpage.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Landingpage