import React from "react"
import styled from "styled-components"

const StyledFooter = styled.footer`
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    font-size: 12px;
    text-align: center;

    @media only screen and (min-width: 600px) {
        footer {
            position: fixed;
            bottom: 1rem;
            left: 0;
        }
    }
`;

const Footer = ({page}) => {
    const color = page === 'landingpage' ? '#fff' : '#000';

    return (
        <StyledFooter color={color}>
            <p style={{color, fontFamily: 'Volante-Regular'}}>
                © {new Date().getFullYear()} - A GAME ABOVE LLC <br />
                <adress>
                    mail: info@agameabove.com - Phone: +1 323 841 5853<br />
                    address: A GAME ABOVE LLC - 4610 S. Ulster Street Suite 150 - Denver - Colorado - CO 80237 - USA
                </adress>
            </p>
        </StyledFooter>
    )
}

export default Footer