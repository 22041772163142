import React from 'react'
import styled from "styled-components"

import Blue from './AGA-Blue.jpg'
import Gold from './AGA-Gold.png'
import Brown from './AGA-Brown.jpg'

const StyledBackground = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: rgb(161, 133, 94);
    background-image: url(${props => props.background});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.99;
    padding: 0 0 4rem;
    z-index: 99;
`;

const BackgroundImage = ({color, children}) => {
    let image = null;

    switch(color) {
        case 'Blue':
            image = Blue;
            break;
        case 'Gold':
            image = Gold;
            break;
        case 'Brown':
            image = Brown;
            break;
        default:
            image = '';
            break;
    }

    return (
        <StyledBackground background={image}>
            {children}
        </StyledBackground>
    )
}

export default BackgroundImage
